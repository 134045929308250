import { Box, useTheme, Text, Stack } from "@bookingcom/bui-react";
import { t, useI18n } from "@bookingcom/lingojs-react";
import SearchboxController from "@bookingcom/flights-searchbox/component-controllers/SearchboxController.js";
import { SearchBoxHorizontalDefault } from "@bookingcom/flights-searchbox/components/SearchBox/index.js";
import React from "react";
import Container from "../Container";
import NoJS from "screens/NoJS";
import useSearchbox from "hooks/useSearchbox";
import useLocaleContext from "hooks/useLocaleContext";
import { trackExperiment } from "../../../utils/et";
import useLandingPageEvents from "hooks/c360/useLandingPageEvents";
import { useTrackFlyAnywhere } from "hooks/useTrackFlyAnywhere";
import useHomeRecentSearch from "components/elements/HomeRecentSearch/useHomeRecentSearch.controller";
import styles from "./HomeSearchHeader.desktop.module.css";

const offset = 56; // Half the searcharea height
const offsetAddition = 8;

const HomeSearchHeader = () => {
  const theme = useTheme();
  const i18n = useI18n();
  const searchBoxProps = useSearchbox();
  const { isRTL } = useLocaleContext();
  const trackLandingPageEvents = useLandingPageEvents();
  const { trackFlyAnywhereWww } = useTrackFlyAnywhere();
  const { searchHistoryList } = useHomeRecentSearch();

  return (
    <>
      <Box
        backgroundColor="brand_primary"
        attributes={{
          style: {
            width: "100%",
            paddingTop: theme.units.spacing_8x,
            paddingBottom: trackExperiment("flights_web_cat_homepage_header_update_www")
              ? offset + offsetAddition
              : offset
          }
        }}
      >
        <Container style={{ padding: `0 0 ${theme.units.spacing_8x} 0` }}>
          <NoJS />
          <div>
            <Stack gap={trackExperiment("flights_web_cat_homepage_header_update_www") ? 1 : 2}>
              <Text
                variant={trackExperiment("flights_web_cat_homepage_header_update_www") ? "display_3" : "headline_1"}
                data-testid="home_header"
                tagName="h1"
                className={trackExperiment("flights_web_cat_homepage_header_update_www") ? styles.title : undefined}
              >
                {i18n.trans(t("flights_index_header_compare_book_ease"))}
              </Text>

              <Text
                variant={trackExperiment("flights_web_cat_homepage_header_update_www") ? "featured_2" : "body_1"}
                className={trackExperiment("flights_web_cat_homepage_header_update_www") ? styles.subtitle : undefined}
              >
                {i18n.trans(t("flights_index_header_discover_next_dream"))}
              </Text>
            </Stack>
          </div>
        </Container>
      </Box>

      <Box
        attributes={{
          style: {
            width: "100%",
            paddingTop: 0,
            paddingBottom:
              trackExperiment("flights_web_cat_homepage_header_update_www") && searchHistoryList.length
                ? theme.units.spacing_8x
                : 0
          }
        }}
      >
        <Container
          style={{
            padding: 0,
            transform: trackExperiment("flights_web_cat_homepage_header_update_www")
              ? `translateY(-${offset + offsetAddition}px)`
              : `translateY(-${offset}px)`,
            marginBottom: -offset
          }}
        >
          <SearchboxController
            i18n={i18n}
            {...searchBoxProps}
            hideShadow={true}
            isRTL={isRTL}
            isHeaderOnDarkBg={!!trackExperiment("flights_web_cat_homepage_header_update_www")}
            isNewUiExp={!!trackExperiment("flights_web_cat_homepage_header_update_www")}
            trackFlyAnywhereWww={trackFlyAnywhereWww}
            onSearch={(searchParams) => {
              trackLandingPageEvents("click", "searchbox");
              searchBoxProps.onSearch(searchParams);
            }}
          >
            {SearchBoxHorizontalDefault}
          </SearchboxController>
        </Container>
      </Box>
    </>
  );
};

export default HomeSearchHeader;

import { AspectRatio, Box, Carousel, Image, Text } from "@bookingcom/bui-react";
import React, { memo, useCallback, useMemo } from "react";
import { UIFlightsThemeRoute } from "@flights/types";
import styles from "./styles.module.css";
import useCreateUrl from "hooks/useCreateUrl";
import { useFormatDateTime } from "@bookingcom/flights-core/hooks";
import { t, useI18n } from "@bookingcom/lingojs-react";
import { trackCustomGoal } from "utils/et";
import { formatPrice } from "utils/format-price";

export const ThemeCarousel = memo(function _ThemeCarousel({
  themeId,
  fromCity,
  destinations,
  isMobile
}: {
  themeId: string;
  fromCity: { name: string; iata: string };
  destinations: UIFlightsThemeRoute[];
  isMobile: boolean;
}) {
  const i18n = useI18n();

  const onCarouselNext = useCallback(() => {
    isMobile
      ? trackCustomGoal("flights_web_cat_theme_based_destinations_mdot", 5)
      : trackCustomGoal("flights_web_cat_theme_based_destinations_www", 5);
  }, [isMobile]);

  return (
    <Carousel
      size={"small"}
      nextButtonAriaLabel={i18n.trans(t("a11y_flights_index_destination_recommendation_next_button_aria_label"))}
      previousButtonAriaLabel={i18n.trans(
        t("a11y_flights_index_destination_recommendation_previous_button_aria_label")
      )}
      onAfterNavigate={onCarouselNext}
    >
      {destinations.map((destination) => (
        <DestinationCard
          key={`theme_destination_${themeId}_${destination.toCity.iata}`}
          isMobile={isMobile}
          fromCityIata={fromCity.iata}
          {...destination}
        />
      ))}
    </Carousel>
  );
});

const DestinationCard = memo(function _DestinationCard({
  fromCityIata,
  toCity,
  departureDate,
  returnDate,
  tripType,
  price,
  isMobile
}: UIFlightsThemeRoute & { fromCityIata: string; isMobile: boolean }) {
  const { createUrlWithApiPrefix } = useCreateUrl();
  const i18n = useI18n();
  const { formats } = useFormatDateTime(i18n);

  const searchResultsLink = useMemo(() => {
    const searchParams = new URLSearchParams();
    searchParams.set("type", tripType);
    searchParams.set("adults", "1");
    searchParams.set("cabinClass", "ECONOMY");
    searchParams.set("sort", "BEST");

    searchParams.set("depart", departureDate);
    returnDate && searchParams.set("return", returnDate);
    searchParams.set("from", fromCityIata);
    searchParams.set("to", toCity.iata);

    return createUrlWithApiPrefix(`/flights/${fromCityIata}-${toCity.iata}/?${searchParams.toString()}`);
  }, [toCity.iata, fromCityIata, departureDate, returnDate, tripType, createUrlWithApiPrefix]);

  const onItemClick = useCallback(() => {
    isMobile
      ? trackCustomGoal("flights_web_cat_theme_based_destinations_mdot", 4)
      : trackCustomGoal("flights_web_cat_theme_based_destinations_www", 4);
  }, [isMobile]);

  return (
    <a href={searchResultsLink} className={styles.card_link} onClick={onItemClick} onAuxClick={onItemClick}>
      <Box padding={0}>
        <AspectRatio ratio="3:2">
          <Image src={toCity.imageUrl} alt="" fallback="background" borderRadius={200} />
        </AspectRatio>
        <div className={styles.card_textContainer}>
          <Text variant="strong_1">{toCity.name}</Text>
          {!!departureDate && (
            <Text variant="body_2" color="neutral_alt">
              {returnDate
                ? `${formats.flightDateWeekday(departureDate)} - ${formats.flightDateWeekday(returnDate)}`
                : `${formats.flightDateWeekday(departureDate)}`}
            </Text>
          )}
          {!!price && (
            <Text variant="strong_2" color="neutral_alt" className={styles.card_textContainer_price}>
              {i18n.trans(
                t("flights_index_destination_recommendation_label_from_price", {
                  variables: { price: formatPrice(price) }
                })
              )}
            </Text>
          )}
        </div>
      </Box>
    </a>
  );
});
